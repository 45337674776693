import $ from "jquery";
import 'slick-carousel';

function slickTestimonials(){
    $(".testimonial__container").each(function(index, element) {
        $(this).not('.slick-initialized').slick({
            arrows: true,
            appendArrows: '.testimonial__dots',
            dots: true,
            appendDots: $(this).siblings('.testimonial__dots'),
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000
        }); 
    });
}

$(document).ready(function(){
    slickTestimonials();
});

