document.addEventListener("DOMContentLoaded", e => {
    const playVideo =  document.querySelectorAll('.video__play-icon');
    playVideo.forEach(element => {
        element.addEventListener('click', (e) => {
            let video = e.target.previousElementSibling;
            if(video.classList.contains('video__video')) {
                e.target.classList.toggle("d-none");
                video.play();
                const controls = video.getAttribute('data-controls');
                controls ? video.setAttribute("controls","controls") : null;
            }
        } )
    });
})
