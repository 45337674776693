document.addEventListener('DOMContentLoaded', function () {
    const blocks = document.querySelectorAll('.wp-block-columns');

    blocks.forEach(function (block) {
        const parent = block.closest('.wp-column');
        if (parent) {
            const computedStyle = window.getComputedStyle(block);
            const bgColor = computedStyle.backgroundColor;
            parent.style.backgroundColor = bgColor;
        }
    });
});