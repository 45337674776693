import $ from "jquery";
import 'slick-carousel';

function createSlick(){
    $(".carousel__container").each(function(index, element) {
        $(this).not('.slick-initialized').slick({
            arrows: true,
            appendArrows: $(this).siblings('.carousel__dots'),
            dots: true,
            appendDots: $(this).siblings('.carousel__dots'),
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000
        }); 
    });
}

$(document).ready(function(){
    createSlick();
});